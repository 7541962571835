import { Link } from 'gatsby'
import React from 'react'

const Button = ({ variant, className, ...props }) => {
  const Component = typeof props.to === 'undefined' ? 'button' : Link
  return (
    <Component
      className={`inline-block h-12 leading-12 px-6  whitespace-nowrap rounded ${
        props.disabled ? 'cursor-not-allowed' : 'hover:opacity-75'
      } ${
        props.disabled || variant === 'gray'
          ? 'bg-gray-500 bg-opacity-10 text-gray-300'
          : `bg-${variant}-500 font-bold cursor-pointer text-white`
      } ${className || ''}`}
      {...props}
    />
  )
}

export default Button
