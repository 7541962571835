import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Logo = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query LogoQuery {
          datoCmsReglage {
            logo {
              url
              alt
            }
          }
        }
      `}
      render={(data) => (
        <img
          src={data.datoCmsReglage.logo.url}
          alt={data.datoCmsReglage.logo.alt}
          className="w-32 md:w-36"
          {...props}
        />
      )}
    />
  )
}

export default Logo
